import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import GoogleAuth from './app/components/googleAuth/GoogleAuth';
import Register from './app/components/registration-process/Register';
import Login from './app/components/Login/login';
import 'antd/dist/reset.css'; // Ant Design reset styles
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/googleauth" element={<GoogleAuth />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to="/register" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
