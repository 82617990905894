import React, { useState } from 'react';
import { Form, Input, Button, DatePicker, Select, Typography, Row, Col, message } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import moment from 'moment'; 
import '../registration-process/Register.css';

const { Title, Link } = Typography;
const { Option } = Select;

const Login = () => {
  const [passwordStrength, setPasswordStrength] = useState('');
  const [form] = Form.useForm();

  // Password strength validation
  const validatePassword = (value) => {
    if (!value) {
      setPasswordStrength('');
      return Promise.reject(new Error('Password cannot be empty'));
    }
    if (value.length < 8) {
      setPasswordStrength('Weak');
      return Promise.reject(new Error('Password must be at least 8 characters long'));
    }
    if (value.length < 10) {
      setPasswordStrength('Medium');
      return Promise.resolve();
    }
    setPasswordStrength('Strong');
    return Promise.resolve();
  };

  // Handle form submission
  const onFinish = (values) => {
    message.success('Account created successfully!');
    console.log('Form Values:', values);
  };

  return (
    <div className="signup-container">
      <Row justify="center" align="middle" style={{ height: '100vh' }}>
        <Col xs={24} sm={18} md={12} lg={10} xl={8}>
          <div className="signup-box">
            <div className="top-right-link">
              Not a member yet? <Link href="/register">Join</Link>
            </div>
            <Title level={1} style={{ textAlign: 'center' }}>
              Log in
            </Title>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              className="signup-form"
            >
             

              {/* Gmail */}
              <Form.Item
                name="gmail"
                label="gmail"
                rules={[
                  { required: true, message: 'Please enter your email!' },
                  { type: 'email', message: 'Please enter a valid email address!' },
                ]}
              >
                <Input placeholder="Gmail" />
              </Form.Item>

              {/* Password */}
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: 'Please enter your password!' },
                  {
                    validator: (_, value) => validatePassword(value),
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              {passwordStrength && (
                <div
                  className={`password-strength ${passwordStrength.toLowerCase()}`}
                >
                  Password strength: {passwordStrength}
                </div>
              )}

              {/* Create Account Button */}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  className="create-account-button"
                >
                  Log in to account
                </Button>
              </Form.Item>

              {/* Sign Up with Google */}
              <Form.Item>
                <Button
                  type="default"
                  icon={<GoogleOutlined />}
                  block
                  className="google-signup-button"
                >
                  Log in with Google
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
