import React, { useState } from 'react';
import { Form, Input, Button, DatePicker, Select, Typography, Row, Col, message } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import moment from 'moment'; 
import './Register.css';

const { Title, Link } = Typography;
const { Option } = Select;

const Register = () => {
  const [passwordStrength, setPasswordStrength] = useState('');
  const [form] = Form.useForm();

  // Password strength validation
  const validatePassword = (value) => {
    if (!value) {
      setPasswordStrength('');
      return Promise.reject(new Error('Password cannot be empty'));
    }
    if (value.length < 8) {
      setPasswordStrength('Weak');
      return Promise.reject(new Error('Password must be at least 8 characters long'));
    }
    if (value.length < 10) {
      setPasswordStrength('Medium');
      return Promise.resolve();
    }
    setPasswordStrength('Strong');
    return Promise.resolve();
  };

  // Handle form submission
  const onFinish = (values) => {
    message.success('Account created successfully!');
    console.log('Form Values:', values);
  };

  return (
    <div className="signup-container">
      <Row justify="center" align="middle" style={{ height: '100vh' }}>
        <Col xs={24} sm={18} md={12} lg={10} xl={8}>
          <div className="signup-box">
            <div className="top-right-link">
              Already a member? <Link href="/login">Login</Link>
            </div>
            <Title level={1} style={{ textAlign: 'center' }}>
              Register
            </Title>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              className="signup-form"
            >
              {/* Name Fields */}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[{ required: true, message: 'Please enter your first name!' }]}
                  >
                    <Input placeholder="First name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[{ required: true, message: 'Please enter your last name!' }]}
                  >
                    <Input placeholder="Last name" />
                  </Form.Item>
                </Col>
              </Row>

              {/* Gmail */}
              <Form.Item
                name="email"
                label="Work Email"
                rules={[
                  { required: true, message: 'Please enter your email!' },
                  { type: 'email', message: 'Please enter a valid email address!' },
                ]}
              >
                <Input placeholder="Work Email" />
              </Form.Item>

              {/* Date of Birth */}
              <Form.Item
                name="dateOfBirth"
                label="Date of Birth"
                rules={[
                  { required: true, message: 'Please select your date of birth!' },
                ]}
              >
                <DatePicker
                  placeholder="Select Date"
                  style={{ width: '100%' }}
                  format="DD/MM/YYYY"
                  disabledDate={(current) => current && current > moment().endOf('day')} // Disable future dates
                />
              </Form.Item>

              {/* Gender */}
              <Form.Item
                name="gender"
                label="Gender"
                rules={[{ required: true, message: 'Please select your gender!' }]}
              >
                <Select placeholder="Select Gender">
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>

              {/* Password */}
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: 'Please enter your password!' },
                  {
                    validator: (_, value) => validatePassword(value),
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              {passwordStrength && (
                <div
                  className={`password-strength ${passwordStrength.toLowerCase()}`}
                >
                  Password strength: {passwordStrength}
                </div>
              )}

              {/* Create Account Button */}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  className="create-account-button"
                >
                  Create Account
                </Button>
              </Form.Item>

              {/* Sign Up with Google */}
              <Form.Item>
                <Button
                  type="default"
                  icon={<GoogleOutlined />}
                  block
                  className="google-signup-button"
                >
                  Sign up with Google
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
